import { memo } from 'react';
import moment from 'moment';
import {
  all_diet,
  all_drug,
  all_lab,
  allDigitsRgx,
  bordetella,
  category_description,
  code,
  code_tags_ids,
  defaultTextFilterOperators,
  DELETE_KEY_CODES,
  description,
  dhpp,
  emptyOption,
  ENTER_KEY,
  felv,
  free_doses,
  fvrcp,
  group_ids,
  groupsFilterOperators,
  has_transactions,
  influenza,
  is_parasiticide,
  jobMappingTextFilterOperators,
  lepto,
  list_price,
  lyme,
  mapped_at,
  mapped_by,
  mappingJobArrayOperators,
  mappingJobCompareOperators,
  non_zero_price,
  non_zero_usage,
  not_mapped_by,
  paid_doses,
  PARA_AVG_COLUMN_COLOR,
  PARA_MODE_COLUMN_COLOR,
  pms_code_vetsuccess_id,
  practice_id,
  PSEUDO_EDIT_CLASS,
  quantity_mode,
  quantity_mode_price,
  rabies,
  rattlesnake,
  revenue_category_id,
  review_status,
  times_used,
  total_revenue,
  VACCINE_COLUMN_COLOR,
  verification_pipeline_status_id,
  verified,
} from '../../constants/constants';
import TableSetFilter from './filters/TableSetFilter';
import TableTextFilter from './filters/TableTextFilter';
import TableNumberFilter from './filters/TableNumberFilter';
import SingleSelectCellEditor from '../singleSelectCellEditor';
import {
  getFilterSelectElements,
  getMappableElements,
} from '../../helpers/selectHelper';
import MultiselectCellEditor from '../multiselectCellEditor';
import ToggleCellEditor from '../toggleCellEditor';
import TableRadioFilter from './filters/TableRadioFilter';
import TableCheckboxFilter from './filters/TableCheckboxFilter';
import TableDateFilter from './filters/TableDateFilter';
import PseudoTextEditor from '../PseudoTextEditor';
import {
  cellValueChanged,
  formatCTValues,
  getValueById,
  noTranslateRenderer,
  parseNameFromStringWithId,
  removeBracketsAndNumbers,
  verifiedRenderer,
} from '../../helpers/tableHelper';
import ClientHasTransactionsFilter from './filters/ClientHasTransactionsFilter';
import ClientRevenueCategorySetFilter from './filters/ClientRevenueCategorySetFilter';
import { CUSTOM_JOB_TYPE } from '../../constants/jobConstants';
import { ArrowDown } from './tableRenders';

export const getColumnDefinitions = ({
  tableRef,
  allRevenueCategories,
  availableCodeTags,
  practices,
  groups,
  practiceGroupRelationships,
  parasiticideRevenueCategories,
  drugRevenueCategories,
  dietRevenueCategories,
  labRevenueCategories,
  pipelineStatusesForSelect,
  users,
  jobType,
  populatedVaccineColumns,
  readOnly = false,
}) => {
  const headerValue = (field, abbreviation, screenSize = 1280) => {
    return tableRef.current.clientWidth < screenSize ? abbreviation : field;
  };

  const singleLineCellRenderer = (value, readOnly = false) => {
    return (
      <div translate="no" className="flex flex-row">
        <div className="flex-1">{value ? value : '\u2014'}</div>
        {!readOnly && <ArrowDown />}
      </div>
    );
  };

  const multiLineCellRenderer = (params, readOnly = false) => {
    return (
      <div translate="no" className="flex flex-row">
        <div className="flex-1">
          {params.value ? removeBracketsAndNumbers(params.value) : '\u2014'}
        </div>
        {!readOnly && !params.value && <ArrowDown />}
      </div>
    );
  };

  return [
    {
      field: '',
      headerName: 'Select All Checkbox',
      headerValueGetter: () => '',
      valueFormatter: () => '',
      width: 25,
      maxWidth: 25,
      resizable: false,
      headerCheckboxSelection: true,
      suppressFillHandle: true,
      suppressHeaderMenuButton: true,
    },
    {
      field: practice_id.field,
      headerName: practice_id.columnName,
      headerTooltip: practice_id.tooltip,
      headerClass: 'practice-header',
      cellClass: 'practice-cell',
      // tweak to use checkbox empty cell for practice
      cellStyle: {
        position: 'absolute',
        left: '0',
        paddingLeft: '30px',
        width: '90px',
      },
      width: 75,
      suppressFillHandle: true,
      filter: TableSetFilter,
      filterParams: {
        values: practices,
        async: true,
      },
    },
    {
      field: group_ids.field,
      headerName: group_ids.columnName,
      headerTooltip: group_ids.tooltip,
      valueGetter: (params) =>
        practiceGroupRelationships[params.data[practice_id.field]] || [],
      width: 75,
      comparator: (valueA, valueB) => {
        if (valueA.length === 0 && valueB.length === 0) {
          return 0;
        } else if (valueA.length === 0) {
          return 1;
        } else if (valueB.length === 0) {
          return -1;
        }
        return valueA.toString().localeCompare(valueB.toString());
      },
      suppressFillHandle: true,
      hide: true,
      filter: TableSetFilter,
      filterParams: {
        values: groups,
        operators: groupsFilterOperators,
      },
    },
    {
      field: pms_code_vetsuccess_id.field,
      headerName: pms_code_vetsuccess_id.columnName,
      headerTooltip: pms_code_vetsuccess_id.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          pms_code_vetsuccess_id.abbreviation,
          1500
        ),
      autoHeight: true,
      width: 100,
      suppressFillHandle: true,
      hide: true,
      filter: TableTextFilter,
      filterParams: {
        operators: jobMappingTextFilterOperators,
        maxNumberOfFilters: 2,
      },
    },
    {
      field: code.field,
      headerName: code.columnName,
      headerTooltip: code.columnName,
      suppressFillHandle: true,
      width: 60,
      filter: TableTextFilter,
      filterParams: {
        operators: jobMappingTextFilterOperators,
        maxNumberOfFilters: 6,
        hasOr: true,
      },
    },
    {
      field: times_used.field,
      headerName: times_used.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, times_used.abbreviation, 1500),
      headerTooltip: times_used.columnName,
      suppressFillHandle: true,
      width: 60,
      filter: TableNumberFilter,
      filterParams: {
        values: mappingJobCompareOperators,
      },
    },
    {
      field: total_revenue.field,
      headerName: total_revenue.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, total_revenue.abbreviation),
      valueFormatter: (params) => `$${params.value}`,
      headerTooltip: total_revenue.columnName,
      width: 70,
      suppressFillHandle: true,
      filter: TableNumberFilter,
      filterParams: {
        values: mappingJobCompareOperators,
      },
    },
    {
      field: list_price.field,
      headerName: list_price.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, list_price.abbreviation),
      valueFormatter: (params) => `$${params.value}`,
      headerTooltip: list_price.columnName,
      suppressFillHandle: true,
      width: 60,
      filter: TableNumberFilter,
      filterParams: {
        values: mappingJobCompareOperators,
      },
    },
    {
      field: category_description.field,
      headerName: category_description.columnName,
      headerValueGetter: (params) =>
        headerValue(
          params.colDef.headerName,
          category_description.abbreviation
        ),
      headerTooltip: category_description.columnName,
      autoHeight: true,
      width: 140,
      suppressFillHandle: true,
      filter: TableTextFilter,
      filterParams: {
        operators: jobMappingTextFilterOperators,
        maxNumberOfFilters: 6,
        hasOr: true,
      },
      cellClass: PSEUDO_EDIT_CLASS,
      singleClickEdit: false,
      editable: true,
      cellEditorSelector: () => {
        return {
          component: PseudoTextEditor,
          popup: false,
        };
      },
      //prevent changing value in column by pasting
      valueSetter: () => false,
    },
    {
      field: description.field,
      headerName: description.columnName,
      autoHeight: true,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, description.abbreviation),
      headerTooltip: description.columnName,
      width: 150,
      suppressFillHandle: true,
      filter: TableTextFilter,
      filterParams: {
        operators: jobMappingTextFilterOperators,
        maxNumberOfFilters: 6,
        hasOr: true,
      },
      singleClickEdit: false,
      cellClass: PSEUDO_EDIT_CLASS,
      editable: true,
      cellEditorSelector: () => {
        return {
          component: PseudoTextEditor,
          popup: false,
        };
      },
      //prevent changing value in column by pasting
      valueSetter: () => false,
    },
    {
      field: 'parasiticides_average',
      headerName: 'Average Sales Excluding Free Transactions',
      headerTooltip: 'Average Sales Excluding Free Transactions',
      children: [
        {
          colId: non_zero_usage.columnName,
          columnGroupShow: 'open',
          width: 85,
          headerName: non_zero_usage.columnName,
          headerTooltip: non_zero_usage.columnName,
          cellStyle: { backgroundColor: PARA_AVG_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[non_zero_usage.field][non_zero_usage.nested_field],
          valueFormatter: (params) =>
            params.value ? `${params.value}%` : '\u2014',
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: non_zero_price.columnName,
          columnGroupShow: 'open',
          width: 85,
          headerName: non_zero_price.columnName,
          headerTooltip: non_zero_price.columnName,
          cellStyle: { backgroundColor: PARA_AVG_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[non_zero_price.field][non_zero_price.nested_field],
          valueFormatter: (params) =>
            params.value ? `$${params.value}` : '\u2014',
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
      ],
    },
    {
      field: 'parasiticide_mode',
      headerName: 'Mode',
      headerTooltip: 'Mode',
      children: [
        {
          colId: quantity_mode.columnName,
          columnGroupShow: 'open',
          width: 85,
          headerName: quantity_mode.columnName,
          headerTooltip: quantity_mode.columnName,
          cellStyle: { backgroundColor: PARA_MODE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[quantity_mode.field][quantity_mode.nested_field],
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: quantity_mode_price.columnName,
          columnGroupShow: 'open',
          width: 90,
          headerName: quantity_mode_price.columnName,
          headerTooltip: quantity_mode_price.columnName,
          cellStyle: { backgroundColor: PARA_MODE_COLUMN_COLOR },
          valueGetter: (params) =>
            params.data[quantity_mode_price.field][
              quantity_mode_price.nested_field
            ],
          valueFormatter: (params) =>
            params.value ? `$${params.value}` : '\u2014',
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
      ],
    },
    {
      field: 'vacc_para_data',
      headerName: 'Vaccine Frequency',
      headerTooltip:
        populatedVaccineColumns.size === 0
          ? 'No vaccine data found'
          : 'Vaccine Frequency',
      children: [
        {
          colId: dhpp.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            dhpp.nested_field
          ),
          width: 80,
          headerName: dhpp.columnName,
          headerTooltip: dhpp.columnName,
          valueGetter: (params) =>
            params.data[dhpp.field][dhpp.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: rabies.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            rabies.nested_field
          ),
          width: 80,
          headerName: rabies.columnName,
          headerTooltip: rabies.columnName,
          valueGetter: (params) =>
            params.data[rabies.field][rabies.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: lepto.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            lepto.nested_field
          ),
          width: 80,
          headerName: lepto.columnName,
          headerTooltip: lepto.columnName,
          valueGetter: (params) =>
            params.data[lepto.field][lepto.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: lyme.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            lyme.nested_field
          ),
          width: 80,
          headerName: lyme.columnName,
          headerTooltip: lyme.columnName,
          valueGetter: (params) =>
            params.data[lyme.field][lyme.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: bordetella.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            bordetella.nested_field
          ),
          width: 80,
          headerName: bordetella.columnName,
          headerTooltip: bordetella.columnName,
          valueGetter: (params) =>
            params.data[bordetella.field][bordetella.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: fvrcp.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            fvrcp.nested_field
          ),
          width: 80,
          headerName: fvrcp.columnName,
          headerTooltip: fvrcp.columnName,
          valueGetter: (params) =>
            params.data[fvrcp.field][fvrcp.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: felv.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            felv.nested_field
          ),
          width: 80,
          headerName: felv.columnName,
          headerTooltip: felv.columnName,
          valueGetter: (params) =>
            params.data[felv.field][felv.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: rattlesnake.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            rattlesnake.nested_field
          ),
          width: 80,
          headerName: rattlesnake.columnName,
          headerTooltip: rattlesnake.columnName,
          valueGetter: (params) =>
            params.data[rattlesnake.field][rattlesnake.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
        {
          colId: influenza.columnName,
          columnGroupShow: 'open',
          suppressColumnsToolPanel: !populatedVaccineColumns.has(
            influenza.nested_field
          ),
          width: 80,
          headerName: influenza.columnName,
          headerTooltip: influenza.columnName,
          valueGetter: (params) =>
            params.data[influenza.field][influenza.nested_field] || 0,
          cellStyle: { backgroundColor: VACCINE_COLUMN_COLOR },
          hide: true,
          filter: TableNumberFilter,
          filterParams: {
            values: mappingJobCompareOperators,
          },
        },
      ],
    },
    {
      field: revenue_category_id.field,
      headerName: revenue_category_id.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, revenue_category_id.abbreviation),
      cellRenderer: memo((params) => {
        let value = null;
        if (params.value) {
          value =
            typeof params.value === 'number' || allDigitsRgx.test(params.value)
              ? getValueById(allRevenueCategories, params.value)
              : parseNameFromStringWithId(params.value);
        }
        return singleLineCellRenderer(value, readOnly);
      }),
      comparator: (valueA, valueB) => {
        if ((valueA === null && valueB === null) || valueA === valueB) {
          return 0;
        }
        if (valueA === null) {
          return 1;
        }
        if (valueB === null) {
          return -1;
        }
        return parseNameFromStringWithId(valueA) >
          parseNameFromStringWithId(valueB)
          ? 1
          : -1;
      },
      cellClass: !readOnly && 'editable-cell',
      autoHeight: true,
      headerTooltip: revenue_category_id.columnName,
      width: 140,
      editable: !readOnly,
      cellEditorSelector: () => {
        return {
          component: SingleSelectCellEditor,
          params: {
            options: allRevenueCategories,
            isSelectable: (o) => o.mappable,
          },
          popup: true,
        };
      },
      filter: TableSetFilter,
      filterParams: {
        values: getFilterSelectElements(
          getMappableElements(allRevenueCategories)
        ),
        operators: defaultTextFilterOperators,
      },
      valueGetter: (params) => {
        if (params.data.revenue_category_id) {
          let category = allRevenueCategories.find(
            (rc) => rc.id === params.data.revenue_category_id
          );
          return category
            ? `${category.value}[${category.id}]`
            : params.data.revenue_category_id;
        } else return null;
      },
      filterValueGetter: (params) => {
        return params.data.revenue_category_id;
      },
    },
    {
      field: code_tags_ids.field,
      headerName: code_tags_ids.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, code_tags_ids.abbreviation),
      cellRenderer: memo((params) => multiLineCellRenderer(params, readOnly)),
      headerTooltip: code_tags_ids.columnName,
      cellClass: !readOnly && 'editable-cell',
      width: 100,
      editable: !readOnly,
      autoHeight: true,
      valueGetter: (params) => {
        if (params.data.code_tag_ids && params.data.code_tag_ids.length > 0) {
          if (Array.isArray(params.data.code_tag_ids)) {
            return formatCTValues(
              getMappableElements(availableCodeTags),
              params.data.code_tag_ids,
              (ct) => `${ct.value}[${ct.id}]`
            );
          } else return params.data.code_tag_ids;
        } else return null;
      },
      suppressKeyboardEvent: (params) => {
        // while editing cell, disable 'Enter'
        // suppress delete so values are not removed by ag grid and we don't lose values in case server errors
        return (
          (params.editing && params.event.key === ENTER_KEY) ||
          DELETE_KEY_CODES.includes(params.event.keyCode)
        );
      },
      cellEditorSelector: () => {
        return {
          component: MultiselectCellEditor,
          params: {
            values: availableCodeTags,
            selectableValues: getMappableElements(availableCodeTags),
          },
          popup: true,
        };
      },
      filter: TableSetFilter,
      filterParams: {
        values: getFilterSelectElements(getMappableElements(availableCodeTags)),
        operators: mappingJobArrayOperators,
      },
      valueSetter: (params) => {
        //skip setting if setting from empty to empty - don't put to undo stack
        if (!cellValueChanged(params.oldValue, params.newValue)) {
          return false;
        } else {
          //if this is not set newValue and oldValue will be same and update in onCellValueChanged will not work correctly
          params.data[code_tags_ids.field] = params.newValue;
          return true;
        }
      },
      filterValueGetter: (params) => {
        return params.data.code_tag_ids;
      },
    },
    {
      field: paid_doses.field,
      headerName: paid_doses.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, paid_doses.abbreviation),
      editable: !readOnly,
      cellEditor: 'agTextCellEditor',
      cellRenderer: memo(noTranslateRenderer),
      headerTooltip: paid_doses.columnName,
      cellClass: !readOnly && 'editable-cell',
      maxWidth: 70,
      width: 67,
      valueSetter: (params) => {
        if (Number.isInteger(parseInt(params.newValue))) {
          params.data[paid_doses.field] = parseInt(params.newValue);
          return true;
        } else {
          return false;
        }
      },
      filter: TableNumberFilter,
      filterParams: {
        values: mappingJobCompareOperators,
      },
    },
    {
      field: free_doses.field,
      headerName: free_doses.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, free_doses.abbreviation),
      editable: !readOnly,
      cellEditor: 'agTextCellEditor',
      cellRenderer: memo(noTranslateRenderer),
      headerTooltip: free_doses.columnName,
      cellClass: !readOnly && 'editable-cell',
      maxWidth: 70,
      width: 67,
      valueSetter: (params) => {
        //handle fill handle
        let newValue = Array.isArray(params.newValue)
          ? params.newValue[0]
          : params.newValue;
        if (Number.isInteger(parseInt(newValue))) {
          params.data[free_doses.field] = parseInt(newValue);
          return true;
        } else {
          return false;
        }
      },
      filter: TableNumberFilter,
      filterParams: {
        values: mappingJobCompareOperators,
      },
    },
    {
      field: verified.field,
      headerName: verified.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, verified.abbreviation),
      headerTooltip: verified.columnName,
      cellRenderer: memo(verifiedRenderer),
      cellClass: !readOnly && 'editable-cell',
      editable: !readOnly,
      width: 60,
      maxWidth: 75,
      valueSetter: (params) => {
        if (verified.values.includes(params.newValue)) {
          params.data[verified.field] = [true, 'true'].includes(
            params.newValue
          );
          return true;
        } else {
          return false;
        }
      },
      cellEditorSelector: () => {
        return {
          component: ToggleCellEditor,
          popup: false,
        };
      },
      filter: TableRadioFilter,
      filterParams: { defaultValue: 'No' },
    },
    {
      field: review_status.field,
      headerName: review_status.columnName,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, review_status.abbreviation),
      cellRenderer: memo((params) => {
        let value = review_status.values_by_key[params.value];
        return singleLineCellRenderer(value ? value : null, readOnly);
      }),
      headerTooltip: review_status.columnName,
      cellClass: !readOnly && 'editable-cell',
      width: 100,
      editable: !readOnly,
      cellEditorSelector: () => {
        return {
          component: SingleSelectCellEditor,
          params: {
            options: review_status.forSelect,
          },
          popup: true,
        };
      },
      valueSetter: (params) => {
        let value = params.newValue;
        //skip setting if setting from empty to empty - don't put to undo stack (initial is undefined and after selecting is null)
        if (
          review_status.emptyMappingValues.includes(params.newValue) &&
          review_status.emptyMappingValues.includes(params.oldValue)
        ) {
          return false;
        } else if (
          Object.keys(review_status.values_by_key).includes(value) ||
          Object.values(review_status.values_by_key).includes(value)
        ) {
          params.data[review_status.field] = review_status.forSelect.find(
            (rs) => {
              return rs.label === value || rs.id === value;
            }
          ).id;
          return true;
        } else if (review_status.emptyMappingValues.includes(value)) {
          //emptyMappingValues holds all of the 'null' values. So by pasting '-' or '', we will map field to null, like when inline editing
          params.data[review_status.field] = null;
          return true;
        } else return false;
      },
      filter: TableCheckboxFilter,
      filterParams: {
        values: review_status.filterValues,
      },
    },
    ...(jobType === CUSTOM_JOB_TYPE
      ? [
          {
            field: verification_pipeline_status_id.field,
            headerName: verification_pipeline_status_id.columnName,
            hide: true,
            headerValueGetter: (params) =>
              headerValue(
                params.colDef.headerName,
                verification_pipeline_status_id.abbreviation
              ),
            cellRenderer: memo((params) => {
              let pipelineStatus = null;
              if (params.value) {
                pipelineStatus =
                  getValueById(pipelineStatusesForSelect, params.value) ===
                  params.value
                    ? 'deleted status'
                    : getValueById(pipelineStatusesForSelect, params.value);
              }
              return singleLineCellRenderer(pipelineStatus, readOnly);
            }),
            headerTooltip: verification_pipeline_status_id.columnName,
            cellClass: !readOnly && 'editable-cell',
            width: 120,
            editable: !readOnly,
            autoHeight: true,
            comparator: (valueA, valueB) => {
              if ((valueA === null && valueB === null) || valueA === valueB) {
                return 0;
              }
              if (valueA === null) {
                return 1;
              }
              if (valueB === null) {
                return -1;
              }
              return getValueById(pipelineStatusesForSelect, valueA) >
                getValueById(pipelineStatusesForSelect, valueB)
                ? 1
                : -1;
            },
            cellEditorSelector: () => {
              return {
                component: SingleSelectCellEditor,
                params: {
                  options: [emptyOption].concat(pipelineStatusesForSelect),
                },
                popup: true,
              };
            },
            filter: TableSetFilter,
            filterParams: {
              values: pipelineStatusesForSelect,
            },
          },
        ]
      : []),
    {
      field: mapped_by.field,
      headerName: mapped_by.columnName,
      hide: true,
      headerValueGetter: (params) =>
        headerValue(params.colDef.headerName, mapped_by.abbreviation),
      valueFormatter: (params) => getValueById(users, params.value),
      headerTooltip: mapped_by.columnName,
      width: 95,
      maxWidth: 120,
      suppressFillHandle: true,
      filter: TableSetFilter,
      filterParams: {
        values: users,
      },
    },
    {
      field: is_parasiticide.field,
      headerName: is_parasiticide.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: ClientRevenueCategorySetFilter,
      filterParams: { parasiticideIds: parasiticideRevenueCategories },
    },
    {
      field: all_drug.field,
      headerName: all_drug.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: ClientRevenueCategorySetFilter,
      filterParams: { parasiticideIds: drugRevenueCategories },
    },
    {
      field: all_diet.field,
      headerName: all_diet.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: ClientRevenueCategorySetFilter,
      filterParams: { parasiticideIds: dietRevenueCategories },
    },
    {
      field: all_lab.field,
      headerName: all_lab.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: ClientRevenueCategorySetFilter,
      filterParams: { parasiticideIds: labRevenueCategories },
    },
    {
      field: mapped_at.field,
      headerName: mapped_at.columnName,
      valueGetter: (params) =>
        params.data.mapped_at
          ? moment(params.data.mapped_at).format('YYYY-MM-DD')
          : null,
      hide: true,
      filter: TableDateFilter,
      suppressFillHandle: true,
      width: 100,
    },
    {
      field: has_transactions.field,
      headerName: has_transactions.columnName,
      hide: true,
      filter: ClientHasTransactionsFilter,
      filterParams: { defaultValue: 'Yes' },
      suppressColumnsToolPanel: true,
    },
    {
      field: not_mapped_by.field,
      headerName: not_mapped_by.columnName,
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableSetFilter,
      filterParams: {
        values: users,
        field: mapped_by.field,
        inverted: true,
      },
    },
  ];
};

export const defaultColumnDefinition = (readOnly) => {
  return {
    wrapText: true,
    width: 60,
    filter: true,
    singleClickEdit: true,
    headerCheckboxSelectionCurrentPageOnly: true,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    suppressFillHandle: readOnly,
    valueFormatter: (params) =>
      params.value != null && params.value !== 0 ? params.value : '\u2014',
    suppressKeyboardEvent: (params) => {
      //suppress delete so values are not removed by ag grid and we don't lose values in case server errors
      return DELETE_KEY_CODES.includes(params.event.keyCode);
    },
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    },
    suppressHeaderKeyboardEvent: (params) => {
      // We need to explicitly call onCellKeyDown event, because it is not being called when header is in focus.
      params.api.eventService.gos.gridOptions.onCellKeyDown(params);
    },
  };
};
